import { reactive, ref, Ref, watch } from "vue";
import * as API from "@/API/payment";
export interface DcnTemplateData {
  fileName: string;
  partyAName: string;
  partyASealStatus: "Y" | "N";
  partyASignStatus: "Y" | "N";
  partyBSealStatus: "Y" | "N";
  protocolSpecification: string;
  assessmentPeriodStatus: "Y" | "N";
  tableTitle: string;
  additionSpecification: string;
  html: string;
}

export type SlotName =
  | "fileName"
  | "fileCode"
  | "partyA"
  | "partyB"
  | "protocolSpecification"
  | "assessmentPeriod"
  | "detailTable"
  | "additionSpecification"
  | "partyASign"
  | "partyBSign";

export type WrapperStyle = Record<string, string>;

export type WrapperStyles = {
  [name in SlotName]: WrapperStyle;
};

export type SlotTmpl = Function | string;

export type SlotTmpls = {
  [name in SlotName]: SlotTmpl;
};

type SlotRefs = {
  [name in SlotName]: Ref;
};

const PANEL_WIDTH = 595;
const PANEL_HEIGHT = 842;
const DEVIATION_PX = 10;

const template = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8"/>
    <title>DCN Template</title>
    <style>
      @page {
        size: ${PANEL_WIDTH}px ${PANEL_HEIGHT}px;
        margin: 0;
      }
      body {
        width: ${PANEL_WIDTH}px;
        height: ${PANEL_HEIGHT}px;
        margin: 0 auto;
        font-size: 14px;
        font-family: "Microsoft YaHei", SimHei, sans-serif;
      }
      * {
        padding: 0;
        margin: 0;
      }
      table {
        border-collapse: collapse;
      }
      .t-a-r {
        text-align: right;
      }
      .file-name-wrapper {
        min-height: 25px;
      }
      .file-name-wrapper .file-name {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
      }
      .file-code-wrapper .file-code {
        display: inline-block;
        white-space: nowrap;
        min-width: 150px;
        padding: 0 10px;
        text-align: left;
      }
      .party-a-wrapper .party-a {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        white-space: nowrap;
      }
      .party-b-wrapper .party-b {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
        text-align: left;
        font-weight: bold;
      }
      .protocol-specification-wrapper .protocol-specification {
        display: inline-block;
        width: ${PANEL_WIDTH - 20}px;
        padding: 0 10px;
        text-align: left;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
      .assessment-period-wrapper .assessment-period {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
        text-align: left;
      }
      .detail-table-wrapper .table-title-wrapper {
        text-align: center;
        font-size: 0;
      }
      .detail-table-wrapper .table-title-wrapper .table-title {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        white-space: pre-wrap;
      }
      .detail-table-wrapper .table-date-wrapper {
        text-align: right;
      }
      .detail-table-wrapper .table-date-wrapper .table-date {
        display: inline-block;
        min-width: 200px;
        padding-right: 10px;
        text-align: left;
      }
      .detail-table-wrapper .table-data-wrapper {
        padding: 0 10px;
      }
      .detail-table-wrapper .table-data-wrapper table {
        border-spacing: 0;
        width: ${PANEL_WIDTH - 20}px;
      }
      .detail-table-wrapper .table-data-wrapper table th, .detail-table-wrapper .table-data-wrapper table td {
        text-align: center;
      }
      .detail-table-wrapper .table-data-wrapper table th.t-a-r, .detail-table-wrapper .table-data-wrapper table td.t-a-r {
        text-align: right;
        padding-right: 5px;
      }
      .detail-table-wrapper .table-data-wrapper table.bbac-table th {
        background-color: #0000CC;
        color: #FFF;
        font-weight: normal;
      }
      .detail-table-wrapper .table-data-wrapper table td {
        word-break: break-all;
      }
      .detail-table-wrapper .table-data-wrapper table th.sales-incentive {
        padding-left: 10px;
        background-color: #C0C0C0;
        text-align: left;
      }
      .detail-table-wrapper .table-data-wrapper table td.total-amount {
        background-color: #C0C0C0;
      }
      .detail-table-wrapper .table-data-wrapper table.fbac-table td.goods-name {
        background-color: #D9E1F2;
      }
      .detail-table-wrapper .table-data-wrapper table.denza-table td.goods-name {
        background-color: #DAEEF3;
      }

      .addition-specification-wrapper .addition-specification {
        display: inline-block;
        width: ${PANEL_WIDTH - 20}px;
        padding: 0 10px;
        text-align: left;
        word-wrap: break-word;
        white-space: pre-wrap;
      }

      .party-a-sign-wrapper .party-a-sign{
        display: inline-block;
        min-width: 200px;
        padding-left: 10px;
        font-weight: bold;
      }

      .party-a-sign-wrapper .party-a-sign p {
        margin-bottom: 10px;
        text-align: left;
        white-space: nowrap;
      }

      .party-a-sign-wrapper .party-a-sign.bbac-party-a-sign p {
        height: 25px;
        margin-bottom: 0;
        text-align: center;
        font-weight: normal;
      }

      .party-b-sign-wrapper .party-b-sign {
        display: inline-block;
        min-width: 200px;
        padding-left: 10px;
        font-weight: bold;
      }
      .party-b-sign-wrapper .party-b-sign p {
        margin-bottom: 10px;
        text-align: left;
        white-space: nowrap;
      }
      /* [css injected] */
    </style>
  </head>
  <body>
    <#assign userMap=dataMap/>
    <!-- [body injected] -->
  </body>
</html>`;

const slotTmpls: SlotTmpls = {
  fileName: ({ fileName }: DcnTemplateData, entity: string) => {
    if (entity === "BBAC") {
      return `<div class="file-name-wrapper">
          <div class="file-name bbac-file-name">${fileName}</div>
        </div>`;
    } else {
      return `<div class="file-name-wrapper">
          <div class="file-name">${fileName}</div>
        </div>`;
    }
  },
  fileCode: (state: DcnTemplateData, entity: string) => {
    if (entity === "BBAC") {
      return `<div class="file-code-wrapper">
          <div class="file-code">\${userMap["fileCode"]}</div>
        </div>`;
    } else {
      return `<div class="file-code-wrapper">
          <div class="file-code">编号：\${userMap["fileCode"]}</div>
        </div>`;
    }
  },
  partyA: (
    { partyAName }: DcnTemplateData,
    entity: string
  ) => `<div class="party-a-wrapper">
      <div class="party-a">甲方：${partyAName}</div>
    </div>`,
  partyB: (state: DcnTemplateData, entity: string) => {
    if (entity === "BBAC") {
      return `<div class="party-a-wrapper">
          <div class="party-a">\${userMap["partyBName"]}</div>
        </div>`;
    } else {
      return `<div class="party-a-wrapper">
          <div class="party-a">乙方：\${userMap["partyBName"]}</div>
        </div>`;
    }
  },
  protocolSpecification: (
    { protocolSpecification }: DcnTemplateData,
    entity: string
  ) => `<div class="protocol-specification-wrapper">
      <p class="protocol-specification">${protocolSpecification}</p>
    </div>`,
  assessmentPeriod: (
    { assessmentPeriodStatus }: DcnTemplateData,
    entity: string
  ) =>
    assessmentPeriodStatus === "Y"
      ? `<div class="assessment-period-wrapper">\
      <div class="assessment-period">考核期间：\${userMap["assessmentPeriod"]}</div>
    </div>`
      : "",
  detailTable: ({ tableTitle }: DcnTemplateData, entity: string) => {
    if (entity === "BBAC") {
      return `<div class="detail-table-wrapper">
        <div class="table-data-wrapper">
          <table border="1" class="bbac-table">
            <tr>
            <th>货物名称</th>
            <th>折让金额</th>
            <th>折让税款</th>
            <th>合计</th>
            </tr>
            <#list tableData as item>
              <tr>
                <td class="goods-name">\${item.name}</td>
                <td class="t-a-r">\${item.discount}</td>
                <td class="t-a-r">\${item.discountTax}</td>
                <td class="t-a-r">\${item.total}</td>
              </tr>
            </#list>
            <tr>
              <th>合计</th>
              <th class="t-a-r">\${userMap["discount"]}</th>
              <th class="t-a-r">\${userMap["discountTax"]}</th>
              <th class="t-a-r">\${userMap["total"]}</th>
            </tr>
          </table>
        </div>
      </div>`;
    } else if (entity === "DEAZA") {
      return `<div class="detail-table-wrapper">
        <div class="table-title-wrapper">
          <p class="table-title">${tableTitle}</p>
        </div>
        <div class="table-date-wrapper">
          <div class="table-date">Date 日期：\${userMap["tableDate"]}</div>
        </div>
        <div class="table-data-wrapper">
          <table border="1" class="denza-table">
            <thead>
              <tr>
                <th>Dealer Name<br/>经销商名称</th>
                <th>\${userMap["partyBName"]}</th>
                <th colspan="2">Dealer SWT Code<br/>经销商SWT代码</th>
                <th>\${userMap["swtCode"]}</th>
              </tr>
              <tr>
                <th colspan="5" class="sales-incentive">Sales Incentive销售折让</th>
              </tr>
              <tr>
                <th>货物名称（车型名称）</th>
                <th>折让金额（元）</th>
                <th>税率</th>
                <th>折让税款（元）</th>
                <th>合计Total（元）</th>
              </tr>
            </thead>
            <tbody>
              <#list tableData as item>
                <tr>
                  <td class="goods-name">\${item.name}</td>
                  <td class="t-a-r">\${item.discount}</td>
                  <td>\${item.tax}</td>
                  <td class="t-a-r">\${item.discountTax}</td>
                  <td class="t-a-r">\${item.total}</td>
                </tr>
              </#list>
              <tr>
                <td class="total-amount">Total Amount<br/>总计</td>
                <td class="t-a-r">\${userMap["discount"]}</td>
                <td>\${userMap["tax"]}</td>
                <td class="t-a-r">\${userMap["discountTax"]}</td>
                <td class="t-a-r">\${userMap["total"]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>`;
    } else {
      return `<div class="detail-table-wrapper">
        <div class="table-title-wrapper">
          <p class="table-title">${tableTitle}</p>
        </div>
        <div class="table-date-wrapper">
          <div class="table-date">Date 日期：\${userMap["tableDate"]}</div>
        </div>
        <div class="table-data-wrapper">
          <table border="1" class="fbac-table">
            <thead>
              <tr>
                <th>Dealer Name<br/>经销商名称</th>
                <th>\${userMap["partyBName"]}</th>
                <th colspan="2">Dealer SWT Code<br/>经销商SWT代码</th>
                <th>\${userMap["swtCode"]}</th>
              </tr>
              <tr>
                <th colspan="5" class="sales-incentive">Sales Incentive销售折让</th>
              </tr>
              <tr>
                <th>货物名称（车型名称）</th>
                <th>折让金额（元）</th>
                <th>税率</th>
                <th>折让税款（元）</th>
                <th>合计Total（元）</th>
              </tr>
            </thead>
            <tbody>
              <#list tableData as item>
                <tr>
                  <td class="goods-name">\${item.name}</td>
                  <td class="t-a-r">\${item.discount}</td>
                  <td>\${item.tax}</td>
                  <td class="t-a-r">\${item.discountTax}</td>
                  <td class="t-a-r">\${item.total}</td>
                </tr>
              </#list>
              <tr>
                <td class="total-amount">Total Amount<br/>总计</td>
                <td class="t-a-r">\${userMap["discount"]}</td>
                <td>\${userMap["tax"]}</td>
                <td class="t-a-r">\${userMap["discountTax"]}</td>
                <td class="t-a-r">\${userMap["total"]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>`;
    }
  },
  additionSpecification: (
    { additionSpecification }: DcnTemplateData,
    entity: string
  ) => `<div class="addition-specification-wrapper">
      <p class="addition-specification">${additionSpecification}</p>
    </div>`,
  partyASign: (
    { partyASignStatus, partyASealStatus, partyAName }: DcnTemplateData,
    entity: string
  ) => {
    if (partyASignStatus === "N") {
      return "";
    }
    if (entity === "BBAC") {
      return `<div class="party-a-sign-wrapper">
          <div class="party-a-sign bbac-party-a-sign">
            <p>${partyAName}</p>
            <p>\${userMap["partyASignDate"]}</p>
          </div>
        </div>`;
    } else {
      return `<div class="party-a-sign-wrapper">
          <div class="party-a-sign">
            <p>甲方：${partyAName}${
        partyASealStatus == "Y" ? "<span>（公章）</span>" : ""
      }</p>
            <p>日期：\${userMap["partyASignDate"]}</p>
          </div>
        </div>`;
    }
  },
  partyBSign: (
    { partyBSealStatus }: DcnTemplateData,
    entity: string
  ) => `<div class="party-b-sign-wrapper">
      <div class="party-b-sign">
        <p>乙方：\${userMap["partyBName"]}${
          partyBSealStatus == "Y" ? "<span>（公章）</span>" : ""
        }</p>
        <p>日期：</p>
      </div>
    </div>`,
};

function attrToClassName(attrName: string) {
  return attrName.replace(/[A-Z]/g, (w) => "-" + w.toLowerCase());
}

function generateCss(wrapperStyles: WrapperStyles) {
  let css = "";
  for (const name in wrapperStyles) {
    const className = `${attrToClassName(name)}-wrapper`;
    css += `.${className} {`;
    for (const attrName in wrapperStyles[name]) {
      css += `${attrToClassName(attrName)}:${wrapperStyles[name][attrName]};`;
    }
    css += "}\n";
  }
  return css;
}

function cloneWrapperStyles(wrapperStyles: WrapperStyles): WrapperStyles;
function cloneWrapperStyles(
  wrapperStyles: WrapperStyles,
  wrapperStyleName: string
): WrapperStyle;

function cloneWrapperStyles(
  wrapperStyles: WrapperStyles,
  wrapperStyleName?: string
): WrapperStyle | WrapperStyles {
  if (wrapperStyleName) {
    return Object.assign({}, wrapperStyles[wrapperStyleName]) as WrapperStyle;
  }
  const cloneObj = {};
  for (const wrapperStyleName in wrapperStyles) {
    cloneObj[wrapperStyleName] = Object.assign(
      {},
      wrapperStyles[wrapperStyleName]
    );
  }
  return cloneObj as WrapperStyles;
}

const useDcnTemplateSet = () => {
  const state = reactive<DcnTemplateData>({
    fileName: "",
    partyAName: "",
    partyASealStatus: "Y",
    partyASignStatus: "Y",
    partyBSealStatus: "Y",
    protocolSpecification: "",
    assessmentPeriodStatus: "Y",
    tableTitle: "",
    additionSpecification: "",
    html: "",
  });

  const resetDefaultState = () => {
    state.fileName = "";
    (state.partyAName = ""),
      (state.partyASealStatus = "Y"),
      (state.partyASignStatus = "Y"),
      (state.partyBSealStatus = "Y"),
      (state.protocolSpecification = ""),
      (state.assessmentPeriodStatus = "Y"),
      (state.tableTitle = ""),
      (state.additionSpecification = ""),
      (state.html = "");
  };

  const searchParams = reactive({
    entity: "",
  });

  // const entities = ref([
  //   'BBAC',
  //   'DENZA',
  //   'FBAC'
  // ])

  const defaultSlotSort: SlotName[] = [
    "fileName",
    "fileCode",
    "partyA",
    "partyB",
    "protocolSpecification",
    "assessmentPeriod",
    "detailTable",
    "additionSpecification",
    "partyASign",
    "partyBSign",
  ];

  const defaultSlotSortBBAC: SlotName[] = [
    "fileCode",
    "partyB",
    "fileName",
    "protocolSpecification",
    "detailTable",
    "additionSpecification",
    "partyASign",
  ];

  const slotSort = ref<SlotName[]>(defaultSlotSort);

  const defaultWrapperStyles = {
    fileName: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    fileCode: {
      marginTop: "5px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "right",
    },
    partyA: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    partyB: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    protocolSpecification: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    assessmentPeriod: {
      marginTop: "30px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    detailTable: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    additionSpecification: {
      marginTop: "30px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    partyASign: {
      marginTop: "50px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    partyBSign: {
      marginTop: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
  };

  const defaultWrapperStylesBBAC = {
    fileName: {
      marginTop: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    fileCode: {
      marginTop: "5px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    partyA: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    partyB: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    protocolSpecification: {
      marginTop: "20px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    assessmentPeriod: {
      marginTop: "30px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
    detailTable: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    additionSpecification: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    partyASign: {
      marginTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    partyBSign: {
      marginTop: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "left",
    },
  };

  const wrapperStyles = reactive<WrapperStyles>(
    cloneWrapperStyles(defaultWrapperStyles)
  );

  const resetSlotSort = (entity: string) => {
    slotSort.value = entity === "BBAC" ? defaultSlotSortBBAC : defaultSlotSort;
  };

  const resetWrapperStyles = (entity: string) => {
    const restetWrapperStyles =
      entity === "BBAC" ? defaultWrapperStylesBBAC : defaultWrapperStyles;
    for (const wrapperStyleName in wrapperStyles) {
      wrapperStyles[wrapperStyleName] = cloneWrapperStyles(
        restetWrapperStyles,
        wrapperStyleName
      );
    }
  };

  watch(
    () => searchParams.entity,
    async (entity) => {
      if (!entity) {
        return;
      }
      const data = (await API.getDcnTemplate(entity)) as Record<
        string,
        unknown
      >;
      if (Object.keys(data).length > 0) {
        for (const key in data) {
          if (key === "slotSort") {
            slotSort.value = JSON.parse(data[key] as string);
          } else if (key === "wrapperStyles") {
            const wrapperStylesJSON = JSON.parse(data[key] as string);
            for (const wrapperStyleName in wrapperStylesJSON) {
              wrapperStyles[wrapperStyleName] =
                wrapperStylesJSON[wrapperStyleName];
            }
          } else {
            state[key] = data[key];
          }
        }
      } else {
        resetDefaultState();
        resetSlotSort(entity);
        resetWrapperStyles(entity);
      }
    },
    {
      immediate: true,
    }
  );

  const fileNameRef = ref(null);
  const fileCodeRef = ref(null);
  const partyARef = ref(null);
  const partyBRef = ref(null);
  const protocolSpecificationRef = ref(null);
  const assessmentPeriodRef = ref(null);
  const detailTableRef = ref(null);
  const additionSpecificationRef = ref(null);
  const partyASignRef = ref(null);
  const partyBSignRef = ref(null);

  const slotRefs: SlotRefs = {
    fileName: fileNameRef,
    fileCode: fileCodeRef,
    partyA: partyARef,
    partyB: partyBRef,
    protocolSpecification: protocolSpecificationRef,
    assessmentPeriod: assessmentPeriodRef,
    detailTable: detailTableRef,
    additionSpecification: additionSpecificationRef,
    partyASign: partyASignRef,
    partyBSign: partyBSignRef,
  };

  const generateDcnTmpl = () => {
    let tmpl = template;
    let bodyTmpl = "";
    slotSort.value?.forEach((name) => {
      if (slotTmpls[name]) {
        if (typeof slotTmpls[name] === "string") {
          bodyTmpl += slotTmpls[name];
        } else {
          bodyTmpl += (slotTmpls[name] as Function)(state, searchParams.entity);
        }
      }
    });
    tmpl = tmpl.replace(/<!-- \[body injected\] -->/, bodyTmpl);
    tmpl = tmpl.replace(
      /\/\* \[css injected\] \*\//,
      generateCss(wrapperStyles)
    );
    return tmpl;
  };

  const saveDcnTmplData = () => {
    const data: any[] = [];
    state.html = btoa(encodeURIComponent(generateDcnTmpl()));
    for (const key in state) {
      data.push({
        attributeKey: key,
        attributeValue: state[key],
      });
    }
    data.push({
      attributeKey: "slotSort",
      attributeValue: JSON.stringify(slotSort.value),
    });
    data.push({
      attributeKey: "wrapperStyles",
      attributeValue: JSON.stringify(wrapperStyles),
    });
    return API.setDcnTemplate(searchParams.entity, data);
  };

  const computeLayout = () => {
    slotSort.value = [];
    for (const name in slotRefs) {
      if (slotRefs[name].value) {
        slotSort.value.push(name as SlotName);
      }
    }

    slotSort.value = slotSort.value.sort((a, b) => {
      return slotRefs[a].value.position.y - slotRefs[b].value.position.y;
    });

    slotSort.value.forEach((name, index, slots) => {
      if (index === 0) {
        wrapperStyles[name].marginTop = `${slotRefs[name].value.position.y}px`;
      } else {
        const prevSlotName = slots[index - 1];
        const marginTop =
          slotRefs[name].value.position.y -
          (slotRefs[prevSlotName].value.position.y +
            slotRefs[prevSlotName].value.size.height);
        wrapperStyles[name].marginTop = `${marginTop}px`;
      }
      const centerDiff =
        slotRefs[name].value.position.x +
        slotRefs[name].value.size.width / 2 -
        PANEL_WIDTH / 2;
      const rightDiff =
        PANEL_WIDTH -
        (slotRefs[name].value.position.x + slotRefs[name].value.size.width);
      if (centerDiff > -DEVIATION_PX && centerDiff < DEVIATION_PX) {
        wrapperStyles[name].textAlign = "center";
        wrapperStyles[name].paddingLeft = "0px";
        wrapperStyles[name].paddingRight = "0px";
      } else if (rightDiff < DEVIATION_PX) {
        wrapperStyles[name].textAlign = "right";
        wrapperStyles[name].paddingLeft = "0px";
        wrapperStyles[name].paddingRight = "0px";
      } else {
        wrapperStyles[name].textAlign = "left";
        wrapperStyles[
          name
        ].paddingLeft = `${slotRefs[name].value.position.x}px`;
        wrapperStyles[name].paddingRight = "0px";
      }
    });
  };

  return {
    PANEL_WIDTH,
    PANEL_HEIGHT,
    searchParams,
    // entities,
    state,
    slotSort,
    wrapperStyles,
    fileNameRef,
    fileCodeRef,
    partyARef,
    partyBRef,
    protocolSpecificationRef,
    assessmentPeriodRef,
    detailTableRef,
    additionSpecificationRef,
    partyASignRef,
    partyBSignRef,
    saveDcnTmplData,
    computeLayout,
  };
};

export default useDcnTemplateSet;
